import ResizeTextArea from 'components/atoms/ResizeTextArea';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import stores from 'stores';
import styled from 'styled-components';
import theme, { fonts } from 'styles/theme';
import DropdownSelect from 'components/atoms/DropdownSelect';
import {
  setHashContent,
  renderInputTextContent,
  changeInputHashContent,
  deleteHashTag,
} from './renderContent';

import { isCursorAfterNbsp, moveCursorToEnd } from 'utils/controlContentEditable';

const TagAutoComplete = observer(
  ({
    projectId,
    editQuetion,
    inputHashValue,
    setHashValue,
    inputTextValues,
    setTextValues,
    onTagAutoSelect,
    inquiryTags,
    placeholder,
  }) => {
    const { roworkPMStore } = stores;

    const [autoCompleteActive, setAutoCompleteActive] = useState(false);
    const [beforeHashValue, setBeforeHashValue] = useState('');

    const [positionX, setXPosition] = useState(0);
    const [positionY, setYPosition] = useState(0);
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (editQuetion) {
        setHashValue(setHashContent(editQuetion[0].content));
        setTextValues(renderInputTextContent(editQuetion[0].content));
      }
    }, [editQuetion, setHashValue, setTextValues]);

    useEffect(() => {
      handleMoveCursor();
    }, []);

    const handleMoveCursor = () => {
      const element = document.getElementById('tagInput');
      moveCursorToEnd(element);
    };

    // 목록에서 일치하는 태그 자동 완성 추천
    const onSearch = useCallback(
      async (text) => {
        const pattern = /(@\S+$)/g;
        const patternEdit = /(@\S+)/g;
        if (inputHashValue.length === 0 && text.match(pattern)) {
          const hash = text.match(pattern)[0];
          const fixedText = text.replace(hash, '');

          // console.log(text.match(pattern)[0]);
          // 태그 자동 완성 추천 api 호출
          let results = await roworkPMStore.searchRoworkPMHash(
            'clients',
            projectId,
            hash.substring(1),
            inquiryTags,
          );

          // let results = hashData.filter((item) => true === matchKeyword(item.hashValue, hash));
          // if (text[0] === '@' && results.length !== 0) setAutoCompleteActive(true);
          if (results.length !== 0) {
            setOptions(results);
            setAutoCompleteActive(true);
            setXPosition(fn_checkByte(fixedText));
            setBeforeHashValue(fixedText);
          } else {
            setAutoCompleteActive(false);
            setOptions([]);
          }
        } else if (inputHashValue.length > 0 && text.match(patternEdit) === null) {
          setHashValue([]);
        } else {
          setAutoCompleteActive(false);
          setOptions([]);
        }
      },
      [inputHashValue.length, inquiryTags, projectId, roworkPMStore, setHashValue],
    );

    console.log(options);

    // textarea 바이트 수 체크하는 함수
    const fn_checkByte = (value) => {
      const text_val = value; //입력한 문자
      const text_len = text_val.length; //입력한 문자수

      let totalByte = 0;
      for (let i = 0; i < text_len; i++) {
        const each_char = text_val.charAt(i);
        // const uni_char = escape(each_char); //유니코드 형식으로 변환
        const uni_char = encodeURIComponent(each_char);
        if (uni_char.length > 4) {
          totalByte += 2; // 한글 : 2Byte
        } else {
          totalByte += 1; // 영문,숫자,특수문자 : 1Byte
        }
      }
      // document.getElementById('nowByte').innerText = totalByte;
      return (totalByte % 68) * 7;
    };

    const targetRef = useRef(null);

    // 태그 자동완성 높이를 위한 설정
    useEffect(() => {
      if (targetRef === null || targetRef.current === null) {
        return;
      }
      setYPosition(targetRef.current.clientHeight);
    }, [targetRef.current?.clientHeight]);

    // contentEditable 비제어 컴포넌트 관리
    useEffect(() => {
      if (targetRef.current.innerHTML !== inputTextValues.join('')) {
        // console.log(inputTextValues);
        targetRef.current.innerHTML = inputTextValues.join('');
      }
    });

    const handleDeleteTag = useCallback(() => {
      setHashValue([]);
      setTextValues(deleteHashTag(inputTextValues.join('')));
      handleMoveCursor();
    }, [inputTextValues, setHashValue, setTextValues]);

    const handleClick = (e) => {
      if (e.target.id === 'deleteTag') {
        handleDeleteTag();
      }
    };

    const handleBackspace = useCallback(
      (e) => {
        if (e.keyCode === 8 && isCursorAfterNbsp()) {
          e.preventDefault();
          handleDeleteTag();
        }
      },
      [handleDeleteTag],
    );

    // 자동 추천된 해시태그 선택시 아이디가 일치하는 태그 목록 활성화를 위한 hashId 넘기기
    const handleGetHashId = useCallback(
      (hashId) => {
        onTagAutoSelect(hashId);
      },
      [onTagAutoSelect],
    );

    const handleOnInput = useCallback(
      (e) => {
        e.preventDefault();
        setTextValues([e.target.innerHTML]);
        onSearch(e.target.innerText);
      },
      [onSearch, setTextValues],
    );

    // 자동완성된 목록 중에 선택항목을 마우스로 클릭 하거나 화살표 버튼으로 선택
    // 해시태그 입력
    const setHashTag = useCallback(
      (e, item) => {
        const { dataId, dataType, inquiryTag, name } = item;
        // setHashValue('@' + innerText + '\u00A0');
        setHashValue([
          '@' + name,
          `<rowork-tag-data data-type="${dataType}" data-id="${dataId}">${name}</rowork-tag-data>`,
        ]);
        handleGetHashId(inquiryTag);
        // let combine = beforeHashValue + '@' + name;
        // setInputValue(combine);
        setTextValues(changeInputHashContent(beforeHashValue, '@' + name));
        handleMoveCursor();
        setAutoCompleteActive(false);
        setOptions([]);
      },
      [setHashValue, handleGetHashId, setTextValues, beforeHashValue],
    );

    return (
      <Wrapper>
        <InputWrapper>
          <ContentEditable
            id="tagInput"
            contentEditable
            suppressContentEditableWarning
            placeholder={placeholder}
            onInput={handleOnInput}
            onKeyDown={handleBackspace}
            onClick={handleClick}
            ref={targetRef}
          >
            {/* {inputTextValue && inputTextValue[0]}
            {inputHashValue[0] && (
              <HashTag>
                {inputHashValue[0]}
                <DeleteHashTag onClick={handleDeleteTag} />
              </HashTag>
            )}
            {inputTextValue && inputTextValue[2]} */}
            {/* <span id="cursor"></span> */}
          </ContentEditable>
          {/* <ContentEditable id="cursor" contentEditable ref={afterRef}></ContentEditable> */}
        </InputWrapper>
        {/* <InputWrapper ref={targetRef}>
          <ResizeInput
            id="tagInput"
            value={inputTextValue}
            placeholder={!inputHashValue[0] ? placeholder : undefined}
            onChange={handleOnChange}
            enterable
          />
        </InputWrapper> */}
        {/* <span id="nowByte">0</span>bytes */}
        {options && autoCompleteActive && (
          <DropdownWrapper left={positionX} top={positionY}>
            <DropdownSelect
              options={options}
              active={autoCompleteActive}
              onClick={setHashTag}
              onSelect={setHashTag}
              type="hashtag"
              visibleDropdown={options && autoCompleteActive}
            />
          </DropdownWrapper>
        )}
      </Wrapper>
    );
  },
);
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const ContentEditable = styled.div`
  ${fonts.body}
  &:focus {
    outline: none;
    border: 0;
  }
  &:empty:before,
  &:focus:empty:before {
    content: attr(placeholder);
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: var(--secondary);
    @media ${theme.mobile} {
      font-size: 16px;
    }
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 5px 18px 5px 8px;
    margin: 0px 6px 4px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    background: var(--primaryLight);
    color: var(--primary);
    position: relative;
    ::after {
      content: '✕';
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      font-size: 12px;
    }
  }
`;

const ResizeInput = styled(ResizeTextArea)`
  margin-left: 0px;
  display: inline-flex;
  flex: 1;
  background: none;
  border: none;
  outline: none;
  padding: 5px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: var(--bodyText);
  word-break: break-all;
  &::placeholder {
    font-size: 24px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    color: var(--secondary);
    @media ${theme.mobile} {
      font-size: 16px;
    }
  }
  &:focus {
    outline: none;
    border: 0;
  }
`;

const DropdownWrapper = styled.ul`
  /* margin-left: 13px; */
  margin-bottom: 0;
  width: 242px;
  min-height: 47px;
  padding: 0;
  max-height: 141px;
  overflow-y: scroll;
  list-style: none;
  position: absolute;
  top: ${(props) => (props.top === 44 ? props.top : props.top + 10)}px;
  left: ${(props) => (props.left > 220 ? 220 : props.left)}px;
  z-index: 1;
  @media ${theme.mobile} {
    max-width: 242px;
    width: 100%;
    left: 0;
  }
`;

export default TagAutoComplete;
