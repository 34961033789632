import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const SEO = ({ title, landing }) => {
  const { t } = useTranslation('common');

  const pageTitles = t('pageTitles');

  return (
    <HelmetProvider>
      <Helmet
        title={
          (pageTitles[title] ? `${pageTitles[title]}` : `${t('menu')[title.props.index]}`) +
          (landing ? '' : ` - ${t('secondTitle')}`)
        }
      />
    </HelmetProvider>
  );
};
